import React, { useEffect, useState } from 'react';
import { Drawer, Button, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import zIndex from '@mui/material/styles/zIndex';
import { flowRight } from 'lodash';
import { Global_Data } from '../../globalData/GlobalData';

const RightDrawer = ({ children ,isOpen, setIsOpen, cloneFormData, setFormData}) => {
 
const navigate = useNavigate()
const {sideBarStyle}= Global_Data()
function removeQueryParameters(url) {
    // Create a URL object from the given URL
    const urlObj = new URL(url);
    
    // Access and return only the base URL (without query parameters)
    return urlObj.origin + urlObj.pathname;
  }
  const toggleDrawer = (open) => () => {

    setIsOpen(open);
    if (!open) {
        // navigate(-1)
        const baseUrl = removeQueryParameters(window.location.href)
        // window.history.pushState({}, '',baseUrl);
        setFormData(cloneFormData)
    }
  };


  return (
    <Box>
      
      <Drawer
        anchor="right"
        open={isOpen}
        id='chutiya'
        // onClose={toggleDrawer(false)}
        // sx={{ '& .MuiDrawer-paper': { width: 'calc(100% - 600px)',  } }}
        sx={sideBarStyle['overlay-form']?.sx}
      >
   
        {/* {JSON.stringify(sideBarStyle['overlay-form'])} */}
        <Box  style={{ position: 'sticky ', top: 0, width:"100%",background:"white",opacity:1, zIndex:999, display:"flex",justifyContent:"end"}}>
        <IconButton
          onClick={toggleDrawer(false)}
          sx={{margin:"20px"}}
          >
          <CloseIcon />
        </IconButton>
          </Box>
        {children}
      </Drawer>
    </Box>
  );
};

export default RightDrawer;
