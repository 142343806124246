import { Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Global_Data } from "../../globalData/GlobalData";
import { serverAddress } from "../../config";
import * as MUIICon from "@mui/icons-material";
import { sprintf } from "sprintf-js";

function VARCUSTOMHTML({ data, varValue, format, value }) {
  const { token,varCutomDialogApi,setvarCutomDialogApi, textValue, setTextValue, mainFormData,setDialogDrawerOpen,setVarCustomDialogTitle,varCustomDialogData, setVarCustomDialogData, modalActionTypeAndID,setFormData } = Global_Data();

  const styleFormatData = format?.data?.records?.find(
    item =>
      (item?.sFieldName == data?.component?.sName &&
        item?.sFieldValue == varValue[data?.component?.sName]) ||
      value
  );
  const Icons = MUIICon[styleFormatData?.sStatusIcon];
  const styleFormat = format?.data?.records?.find(
    item =>
      (item?.sFieldName == data?.component?.sName &&
        item?.sFieldValue == varValue[data?.component?.sName]) ||
      value
  );
  const parsedData = styleFormat ? JSON.parse(styleFormat?.sFieldFormat) : {};

  const formatedData =
  (  varValue?.[data?.component?.sName] || value)
      ? sprintf(data.component.sDisplayFormat, varValue?.[data?.component?.sName] || value)
      : "";

  function addThousandSeparatorToNumber(numberString) {
    // Convert the extracted number to a string with two decimal places
    const formattedNumber = parseFloat(numberString).toFixed(2);

    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = formattedNumber.split(".");

    // Add comma separators to the integer part
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Return the formatted number with the decimal part
    return `${formattedIntegerPart}.${decimalPart}`;
  }

  function formatAllNumbersInString(input) {
    // Use a regular expression to match all numbers in the string
    return input.replace(/-?\d+(\.\d+)?/g, match => addThousandSeparatorToNumber(match));
  }
  const data1 = formatedData;
  let href = "";
  let text = "";
  if (data.component.sMode == "DIALOG") {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data1, "text/html");

    // Find the anchor tag and extract the inner text
    const anchor = doc.querySelector("a");
    if (anchor) {
      href = anchor.getAttribute("href");
      text = anchor.textContent; // or anchor.innerText for older brow
    }
  }

  // Parse the string into a DOM structure

  function handledialogOpen() {

    let uri = serverAddress + href;

    axios
      .get(uri, {
        headers: {
          Authorization: `Bearer ${token}`
          // Other headers if needed
        }
      })
      .then(result => {
        setDialogDrawerOpen(true)
        // alert(JSON.stringify(result?.data));
        // setFormData(result?.data)
        setVarCustomDialogTitle(data?.component?.sDialogTitle)
        setVarCustomDialogData(result?.data)
        setvarCutomDialogApi(href)
      })
      .catch(error => {
        console.error(error, "error456");
      });
  }

  return (
    <Grid item {...data?.grid_props}>
      {/* {JSON.stringify(href)} */}
      {data?.component?.sMode == "DIALOG" && (
        <Typography
          onClick={() => handledialogOpen()}
          {...data?.component?.sProps}
          // {...parsedData}
          //   sx={{color:'primary.main'}}

          {...(styleFormatData?.sFieldValue === varValue?.[data?.component?.sName]
            ? parsedData
            : null)}
        >
          {/* {styleFormatData?.sStatusIcon && <Icons />} */}
          {formatedData.includes(undefined)
            ? ""
            : <span dangerouslySetInnerHTML={{ __html: text }} /> || ""}
        </Typography>
      )}
      {data?.component?.sMode == "OPENINFORM" && (
        <Typography
          {...data?.component?.sProps}
          // {...parsedData}
          //   sx={{color:'primary.main'}}

          {...(styleFormatData?.sFieldValue === varValue?.[data?.component?.sName]
            ? parsedData
            : null)}
        >
          {/* {styleFormatData?.sStatusIcon && <Icons />} */}
          {formatedData.includes(undefined)
            ? ""
            : <span dangerouslySetInnerHTML={{ __html: formatedData }} /> || ""}
        </Typography>
      )}

      {data?.component?.sMode == "NEWTAB" && (
        <Typography
          {...data?.component?.sProps}
          // {...parsedData}
          //   sx={{color:'primary.main'}}

          {...(styleFormatData?.sFieldValue === varValue?.[data?.component?.sName]
            ? parsedData
            : null)}
        >
          {/* {styleFormatData?.sStatusIcon && <Icons />} */}
          {formatedData.includes(undefined)
            ? ""
            : <span dangerouslySetInnerHTML={{ __html: formatedData }} /> || ""}
        </Typography>
      )}
    </Grid>
  );
}

export default VARCUSTOMHTML;
