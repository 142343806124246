import { Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Global_Data } from "../../globalData/GlobalData";
import { serverAddress } from "../../config";
import * as MUIICon from "@mui/icons-material";
import { sprintf, vsprintf } from "sprintf-js";

function VarConcat({ data, varValue, format }) {
  const { token, textValue, setTextValue, mainFormData, modalActionTypeAndID } = Global_Data();

  const styleFormatData = format?.data?.records?.find(
    item =>
      item?.sFieldName == data?.component?.sName &&
      item?.sFieldValue == varValue[data?.component?.sName]
  );
  const Icons = MUIICon[styleFormatData?.sStatusIcon];
  const styleFormat = format?.data?.records?.find(
    item =>
      item?.sFieldName == data?.component?.sName &&
      item?.sFieldValue == varValue[data?.component?.sName]
  );
  const parsedData = styleFormat ? JSON.parse(styleFormat?.sFieldFormat) : {};

  //     const [activityBTN,setActivityBTN] = useState(false)

  //   function fetchActivity() {
  //     const urlCapture =
  //       serverAddress+
  //       `/form/isallowed/transaction?module=${mainFormData.form.sFormName}&activity=${mainFormData.form.sFormAction}`;
  //     axios
  //       .get(urlCapture, {
  //         headers: {
  //           Authorization: `Bearer ${token}`
  //           // Other headers if needed
  //         }
  //       })
  //       .then(result => {

  //         if (result.data.data.Access == "No") {
  //           setActivityBTN(true);
  //         }
  //       })
  //       .catch(error => {
  //         console.error(error, "error456");
  //       });
  //   }

  //   React.useEffect(() => {
  //     fetchActivity();
  //   }, []);

  function extractFields(sDisplayFields, data) {
    // Split the sDisplayFields string into an array of field names
    const fieldNames = sDisplayFields.match(/{([^}]+)}/g).map(f => f.slice(1, -1));

    // Map over the field names and extract the corresponding value from the data object
    const result = fieldNames.map(field => data[field] || "");

    let filteredArr = result.filter(item => item !== "");
    return filteredArr;
  }
  const formatedData = vsprintf(
    data.component.sDisplayFormat,
    extractFields(data.component.sDisplayFields, varValue)
  );
  function addThousandSeparatorToNumber(numberString) {
    // Convert the extracted number to a string with two decimal places
    const formattedNumber = parseFloat(numberString).toFixed(2);

    // Split the number into integer and decimal parts
    const [integerPart, decimalPart] = formattedNumber.split(".");

    // Add comma separators to the integer part
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Return the formatted number with the decimal part
    return `${formattedIntegerPart}.${decimalPart}`;
  }

  function formatAllNumbersInString(input) {
    // Use a regular expression to match all numbers in the string
    return input.replace(/-?\d+(\.\d+)?/g, match => addThousandSeparatorToNumber(match));
  }

  return (
    <Grid item {...data?.grid_props}>
      {/* {JSON.stringify(varValue)} */}
      {/* 
      
{data?.component?.sName} */}
      <Typography
        {...data?.component?.sProps}
        {...parsedData}
        //   sx={{color:'primary.main'}}

        {...(styleFormatData?.sFieldValue === varValue[data?.component?.sName] ? parsedData : null)}
      >
        {/* {JSON.stringify(parsedData)} */}
        {/* {
            {JSON.stringify(styleFormatData.sFieldName)} */}
        {styleFormatData?.sStatusIcon && <Icons />}
        {formatedData.includes(undefined)
          ? ""
          : <span dangerouslySetInnerHTML={{ __html: formatedData }} /> || ""}
        {/* {typeof formatedData } */}
        {/* {formatedData}
       {JSON.stringify( formatedData.includes(undefined))} */}
        {/* {data?.component?.sName} */}

        {/* {JSON.stringify(parsedData)} */}
      </Typography>
    </Grid>
  );
}

export default VarConcat;
