import { Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseURL } from "../../api";
import { Global_Data } from "../../globalData/GlobalData";
import { sprintf, vsprintf } from 'sprintf-js';
function VarValue({ data,textValue, }) {

  const [VarValue, setVarValue] = useState({});             
  const { token } = Global_Data();


  function replacePlaceholders(uri, data) {
    const regex = /{([^}]+)}/g;
    const replacedUri = uri?.replace(regex, (match, key) => {
        return key in data ? data[key] : match;
    });
    return replacedUri;
}

  async function GetVarValueData() {

    try {
      const response = await axios.get(`${baseURL}${ replacePlaceholders(data?.data?.sDataSource,textValue)}`, {
        headers: {
          Authorization: `Bearer ${token}`
          // Other headers if needed
        }
      });
      setVarValue(response.data.data.records[0])
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {

    GetVarValueData()

  }, [data, textValue[data?.data?.sDataAware?.replace(/[{}]/g, '')]]);

  function replacePlaceholders2(template, values) {
    return template?.replace(/{(\w+)}/g, function(match, key) {
        return typeof values?.[key] !== 'undefined' ? values?.[key] : ' ';
    });
}

let dataAwere = data?.data?.sDataAware?.replace(/[{}]/g, '')

const num = +VarValue?.[data?.data?.sDisplayFields?.replace(/[{}]/g, '')] || 0

const fomatedValue = replacePlaceholders2(data?.data?.sDisplayFields,VarValue)

const formattedValue = vsprintf(data?.data?.sDisplayFormat, fomatedValue?.split(','));
let char = fomatedValue;

if (/[a-zA-Z0-9]/.test(char)) {
  char = vsprintf(data?.data?.sDisplayFormat, char?.split(','));
} else {
  char = '';
  console.log(char);
}
function addThousandSeparatorToNumber(numberString) {
  // Convert the extracted number to a string with two decimal places
  const formattedNumber = parseFloat(numberString)?.toFixed(2);

  // Split the number into integer and decimal parts
  const [integerPart, decimalPart] = formattedNumber?.split('.');

  // Add comma separators to the integer part
  const formattedIntegerPart = integerPart?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Return the formatted number with the decimal part
  return `${formattedIntegerPart}.${decimalPart}`;
}

function formatAllNumbersInString(input) {
  // Use a regular expression to match all numbers in the string
  return input?.replace(/-?\d+(\.\d+)?/g, match => addThousandSeparatorToNumber(match));
}
// let sv = {variant:"h4"}
// const formattedValue3 = vsprintf(data.data.sDisplayFormat, char.split(','));
  return (
  <>
{/* {data.data.sDisplayFormat} */}
{/* {JSON.stringify(data?.component?.sProps)} */}
{/*
{JSON.stringify(fomatedValue.split(','))} */}
    {/* // return <Typography sx={{ color: "text.secondary" }}>{JSON.stringify(VarValue)}</Typography>; */}
  <Typography sx={{ color: "text.secondary" }} {...data?.component?.sProps}>
    {
     /[a-zA-Z0-9]/.test(formattedValue) && <div dangerouslySetInnerHTML={{__html: formatAllNumbersInString(char)}}/> 
    } 
     {/* ---- */}
    {/* {fomatedValue} */}
    {/* {fomatedValue.split(',')} */}
    </Typography>
  </>
  )
}

export default VarValue;
